.pageTitle {
    font-weight: 600;
    font-size: 30px;
    font-family: "Inter", sans-serif;

    color: #5a6c7f;
    margin-bottom: 40px;
}

.chapterTitle {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #011d3a;
}

.slick-arrow {
    color: #011d3a;
    top: -40px;
    width: 46px;
    height: 46px;
    border: 2px solid #011d3a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.slick-arrow:hover {
    opacity: 1;
}

.slick-next {
    right: 40px;
    left: unset;
}

.slick-prev {
    right: 105px;
    left: unset;
}

.slick-next:before,
.slick-prev::before {
    content: unset;
}

.slick-next:hover,
.slick-prev:hover {
    color: inherit;
    outline: inherit;
    background: inherit;
}

.slick-disabled {
    color: #c2c2c2 !important;
    border: 2px solid #c2c2c2;
}


.chapter-header {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 170px);
}

.chapter-header-empty {
    width: calc(100% - 39px);
}

.chapter-header-empty button {
    display: none;
}


.chapter-header button {
    margin-top: 5px;
    border-radius: 50px;
    width: 46px;
    min-width: 46px;
    height: 46px;
    background: transparent;
    outline: none;
    border: 2.5px solid #3c9cdb;
}

.chapter-header button i {
    size: 24px;
    color: #3c9cdb;
}
