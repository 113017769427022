:root {
    --notify-color-background: #fff;
    --notify-color-onBackground: #000;
    --notify-dimen-titleHeight: 72px;
}

.container {
    background-color: var(--notify-color-background);
    max-width: 416px;
    min-width: 240px;
    width: 37%;
    margin: 8px;
    border-radius: 8px;
    padding: 0px !important;
    overflow: hidden;
    height: calc(100% - 16px);
    transition: height 200ms ease-in-out;
}

.title {
    height: var(--notify-dimen-titleHeight);
    /*background-color: lightblue;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Inter';
    padding: 24px 28px;
    width: 100%;
    gap: 8px;

}

.container h1 {
    margin: 0 !important;
    font-weight: 600;
    font-size: 16px;
    flex: 1;
}

.counter {
    padding: 8px 12px;
    margin-inline-end: -8px;
    line-height: 100%;
    background: transparent;
    border-radius: 26px;
    font-weight: 600;
    font-size: 16px;
    color: white;
    opacity: 0;
    transition: all 70ms ease-in-out;
}

.headerIcon {
    height: 24px;
    width: 24px;
    size: 24px;
}

.arrow {
    transition: transform 170ms ease-in-out,
            width 70ms ease-in-out;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 8px 120px;
    height: calc(100% - 72px);
    overflow: auto;
}

.list::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.list::-webkit-scrollbar-track {
    background: #f0f1f2;
    border-radius: 8px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
    background: #223d5a;
    border-radius: 8px;
}

/* Handle on hover */
.list::-webkit-scrollbar-thumb:hover {
    background: #3c9cdb;
}

.collapsed {
    height: 72px;
}

.collapsed .counter {
    opacity: 1;
    background: #ED3A3A;
}

.collapsed .arrow {
    transform: rotate(180deg);
    width: 0px;
    overflow: hidden;
}


.notificationInfoMessage {
    text-align: center;
    font-weight: bold;
    size: 18px;
    margin-top: 32px;
}