@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;0,700;1,400&display=swap');

.codeFormContainer {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 100px;
    width: 70%;
    border-radius: 8px;
    min-width: 400px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.codeFormContainer form {
    /* background-color: antiquewhite; */
    padding: 24px;
    width: 30%;
    display: flex;
    min-width: 370px;
    flex-direction: column;
}

.codeFormContainer .formInputLabel {
    display: block !important;
    width: 100%;
}

.formInputLabel {
    margin-bottom: 16px;
    font-weight: bold;
    color: #1a202c;
    font-size: 16px;
    line-height: 19.2px;
}


.formTextInput {
    background-color: #f6f7f9;
    padding: 0px 24px;
    height: 56px;
    outline: none;
    border: 0px;
    border-radius: 10px;
    width: 100%;
    color: #1a202c;
    font-weight: 500;
    appearance: none;
    background-size: 18px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 20px center;
    margin-bottom: 24px;
}

.formTextInput input {

    border: none;

}

.codeFormContainer button {
    margin-inline-end: 0;
    margin: 0 !important;
    border-radius: 4px;
}

.codeFormContainer .formLabel {
    width: 100%;
    margin-bottom: 20px;
    color: #001731;
}

.title-codes {
    font-weight: bold;
}

.counter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.counter button {
    background-color: #f7f8f9;
    color: #64748B;
    width: 25px;
    height: 24px;
    border: none;
    outline: none;
    border-radius: 50px;
    text-align: center;
    font-weight: bolder;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter input {
    border: none !important;
    outline: none;
    background-color: transparent;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: black;
}


.defaultCountersGroup {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.codesSubmitButton {
    text-align: end;
    margin-top: 48px;
}

.codesSubmitButton button {
    background-color: #3c9cdb;
    padding: 8px 16px;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    outline: none;
    border: none;

}

.defaultCountersGroup button {
    border: #e7eaee 2px solid;
    padding: 8px;
    width: 75.75px;
    border-radius: 14px;
    color: black;
    font-weight: 400;
    background-color: transparent;
}

.defaultCountersGroup .selected {
    border-color: black;
    font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
.counter input::-webkit-outer-spin-button,
.counter input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.counter input[type=number] {
    -moz-appearance: textfield;
}

.printBtn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background: rgb(234, 234, 234);
    color: #001731 !important;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    align-items: center;
    justify-content: center;
    display: flex;
    outline: none;
    border: none;
}

.printBtn:hover,
.printBtn:active,
.printBtn:focus {
    background-color: #3c9cdb;
    color: rgb(226, 226, 226);
    outline: none;

}

@media print {
    .title-codes {
        display: none;
    }

    .printBtn {
        display: none;
    }
}