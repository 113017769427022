.container {
    background: #001731;
    color: white;
    text-align: center;
    font-size: 20px;
    padding-bottom: 24px;
}

.container a {
    color: #3c9cdb;
}
